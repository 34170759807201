/*
    GloverSure Foundation Template - CSS in this file is divided into sections: Header, Slideshow etc.

    Styles for other pages like News, Products etc. are split into individual SCSS files in the 'Modules directory'.
*/

@import url('https://fonts.googleapis.com/css?family=PT+Serif:400,700|Poppins:300,400,600,700|Playfair+Display:400,700,900');

/* ===========================] General [======================== */

$f-serif: 'PT Serif',
serif;
$f-sans: 'Poppins',
sans-serif;

@mixin title {
    color: #af965d;
    max-width: 85%;
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 0.85em;

    @media screen and (max-width: 640px) {
        max-width: 100%;
        font-size: 1.8em;
    }
}

@mixin copy {
    line-height: 2;
    font-size: 15px;
    color: #565656;
}

@mixin btn {
    font-family: 'Playfair Display';
    padding: 11px 24px;
    text-transform: uppercase;
    background: 0 0;
    color: #5c5c5c;
    border: 1px solid #d8d8d8;
    margin-top: 0.8em;
    display: inline-block;
    transition: 0.3s;

    &:hover {
        background: $brand;
        color: #fff;
        // border: solid 1px $brand;
    }
}

@mixin linkLight {
    font-family: $f-serif;
    font-weight: bold;
    color: $brand;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 40px;
        left: 100%;
        top: 50%;
        margin-left: 15px;
        transform: translateY(-50%);
        height: 2px;
        background: #eaeaea;
        transition: 0.4s;
    }

    &:hover {
        &::after {
            width: 60px;
        }
    }
}

body,
html {
    background-color: white;
    font-family: $f-sans;
    overflow-x: hidden;
    @include copy;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $f-serif;
}

p,
ul {
    @include copy;
}

.book-scroll-fix {
    position: fixed;
    bottom: 0;
    right: 10px;
    z-index: 3;
    display: flex;

    a {}

    a.book-fix {
        width: 180px;
        height: 50px;
        line-height: 33px;
        background-color: #5c5c5c;
        padding: 10px 38px;
        display: inline-block;
        color: #fff;
        font-family: $f-serif;
        text-align: center;
        font-size: 17px;
        text-transform: uppercase;
        font-family: 'Playfair Display', serif;
    }

    a.scroll-fix {
        width: 50px;
        height: 50px;
        line-height: 50px;
        background-color: $brand;
        padding: 10px 20px;
        margin-left: 10px;
        text-align: center;
        line-height: 32px;

        i {
            color: #fff;
        }
    }
}

.grid-x {
    width: 90%;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;

    &.expanded {
        max-width: 100%;
        width: 100%;
    }

    &.full {
        max-width: 100%;
        width: 100%;
    }
}

hr {
    max-width: 130px;
    margin: 30px 0px 40px;
    border-bottom: solid 3px #e1e1e1;
}

.g-recaptcha {
    margin-bottom: 1em;
}

.scrollup {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: none;
    background-color: #FFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    color: #0A0A0A;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

#cookie-notice {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    // background-color: $brand;
    background-color: rgba(0, 0, 0, 0.68);
    padding: 1em;
    color: #fff;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        margin-bottom: 0px;
        font-size: 0.9em;
        width: 75%;
        color: #fff;
    }

    a {
        // background-color: darken($brand, 10%);
        background-color: $brand;
        padding: 0.7em 1em;
        display: inline-block;
        font-size: 0.8em;
        border-radius: 2px;
        color: #fff;
    }
}

.input-group {
    display: block;
}



#pagewrap {
    //transition: filter 0.4s transform 0.8;
    overflow: hidden;

    .pagewrap-inner {
        transition: 0.4s;
        transform-origin: top;
    }

    &.is-active {
        .pagewrap-inner {
            filter: blur(10px);
            transform: scale(1.03);
        }
    }
}

.mobile-menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    visibility: hidden;
    opacity: 0;
    background: rgba(41, 41, 41, 0.8);
    transition: all 0.5s;

    ul {
        height: 100%;
        width: 100%;

        li {
            margin: 5px 0px;
            transform: translateY(50px);
            opacity: 0;
            transition: 1s cubic-bezier(0.15, 0.27, 0, 1.01);
            flex-direction: column;

            @media screen and (max-width: 640px) {
                margin: 0px;
            }

            a {
                color: #fff;
                font-family: "Playfair Display", serif;
                font-weight: 600;
                font-size: 36px;
                text-align: center;

                @media screen and (max-width: 640px) {
                    font-size: 22px;
                }

                &::after {
                    position: static !important;
                    display: inline-block !important;
                    margin-left: 15px !important;
                    transform: translateY(-8px) rotate(0deg) !important;
                }
            }
        }

        .is-accordion-submenu-parent[aria-expanded=true] > a::after {
            transform: translateY(-8px) rotate(180deg) !important;
        }
    }

    &.is-active {
        transition: all 0.4s;
        visibility: visible;
        opacity: 1;

        ul {
            @for $i from 1 through 6 {
                li:nth-of-type(6n + #{$i}) {
                    transition-delay: $i * 0.15s;
                }
            }

            li {
                transform: none;
                opacity: 1;

                a {}
            }
        }
    }
}

.drop {
    text-align: center;
    margin: 10px 0px !important;

    li {
        display: inline-block !important;
        margin: 0px !important;
        width: auto !important;

        a {
            font-size: 17px !important;
            opacity: 0.7;
            transition: 0.3s;
            font-family: $f-sans !important;

            @media screen and (max-width: 640px) {
                padding: 0.5rem 1rem !important;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}




button.toggle {
    //position: fixed;
    //top: 0;
    //right: 0;
    // border: solid 2px red;
    //z-index: 4;
    cursor: pointer;

    p {
        vertical-align: middle;
        display: inline-block;
        margin: 0px;
        color: #fff;
        font-sizE: 17px;
        text-transform: uppercase;
        font-size: 15px;
        margin-right: 16px;
        transform: translateY(-5px);
    }
}

/* ===========================] End General [======================== */



/* ===========================] Header [======================== */

header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 40px;
    z-index: 4;
    transition: 0.4s;

    @media screen and (max-width: 800px) {
        padding: 20px 25px;
    }

    &.header-scrolled {

        /* Styles for header when fixed + scrolled here, logo shrink etc */
        //  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        .header-left {}
    }
}

.header-left {
    a {
        font-size: 23px;
        color: #fff;
        margin-right: 16px;
    }
}

.header-mid {
    img {
        max-width: 230px;

        @media screen and (max-width: 640px) {
            max-width: 165px;
        }
    }
}

.header-right {}


/* ===========================] End Header [======================== */



/* ===========================] Slideshow [======================== */

.owl-item {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
}

#slideshow {
    position: relative;

    .slide {
        background-color: #000;
        height: 100vh;

        img {
            opacity: 0.85;
            height: 100%;
            object-fit: cover;
        }
    }

    .overlay {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-20%);
        right: 0;
        margin: 0 auto;
        text-align: center;
        opacity: 0;
        // transition: 0.8s;
        transition: 1s cubic-bezier(0.15, 0.27, 0, 1.01);
        transition-delay: 0.8s;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            color: #fff;
            font-size: 3.2em;
            font-family: 'Playfair Display', serif;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 2px;

            @media screen and (max-width: 640px) {
                font-size: 2em;
            }
        }

        a {
            @include btn;
            color: #fff;
            background: #af965d;
            border: none;
            font-size: 16px;
            padding: 6px 28px;
        }

        &.animated {
            /* Animates in the Slideshow text */
            opacity: 1;
            transform: translateY(-50%);
        }

        .slideshow-text {
            max-width: 760px;
            margin: 0 auto;
            width: 88%;
        }
    }

    .owl-prev,
    .owl-next {
        position: absolute;
        height: 55px;
        width: 55px;
        line-height: 55px;
        text-align: center;

        i {
            color: #fff;
            padding: 10px;
            font-size: 24px;
        }
    }

    .owl-prev {
        background-color: lighten($brand, 10%);
        left: 0;
        bottom: 50px;
    }

    .owl-next {
        background-color: $brand;
        left: 0;
        bottom: 0px;
    }
}

/* ===========================] End Slideshow [======================== */

#introduction {
    position: relative;

    .intro-email {
        position: absolute;
        right: 36px;
        top: 84%;
        transform: translateY(-50%) rotate(90deg);
        transform-origin: right;
        opacity: 0.4;

        @media screen and (max-width: 1024px) {
            display: none;
        }

        a,
        p {
            display: inline-block;
            font-family: 'Playfair Display', serif;
            text-transform: uppercase;
        }

        p {
            margin: 0px;
        }
    }

    .intro-social {
        position: absolute;
        left: 40px;
        top: 74%;
        transform: translateY(-50%) rotate(-90deg);
        transform-origin: left;
        opacity: 0.4;

        @media screen and (max-width: 1024px) {
            display: none;
        }

        a {
            margin-left: 12px;
            color: #707070;
        }

        p {
            display: inline-block;
            font-family: 'Playfair Display', serif;
            text-transform: uppercase;
        }
    }

    .grid-x {
        padding: 0px 5%;

        @media screen and (max-width: 1025px) {
            padding: 0 2%;
        }

        //padding-bottom: 200px;
    }

    .intro-title {
        padding: 70px 0px;

        @media screen and (max-width: 800px) {
            padding: 50px 0;
        }

        h3 {
            font-family: $f-sans;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 20px;
            margin: 0px;
            color: #707070;
        }
    }

    .intro-pic-inner {
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        a {
            position: absolute;
            bottom: 20px;
            right: 20px;
            @include btn;
            color: #fff;
            background: $brand;
            border: none;
            font-size: 14px;
            font-family: 'Playfair Display', serif;
            letter-spacing: 1px;
        }

        p#circ-text1 {
            position: absolute;
            bottom: 0px;
            left: 0px;
            transform: translateY(70%) translateX(-50%);
            font-family: 'Playfair Display', serif !important;
            transition: 0.8s;
            margin: 0;
            color: #af965d;
            font-weight: bold;
            font-size: 22px;
            font-family: $f-serif;
            opacity: 0;

            @media screen and (max-width: 1025px) {
                display: none;
            }
        }
    }

    .intro-pic {
        &.aos-animate {
            p#circ-text1 {
                opacity: 1;
                transition-delay: 1s;
                transform: translateY(50%) translateX(-50%);
            }
        }
    }

    .intro-text {

        h1,
        h2,
        h3,
        h4 {
            @include title;
            font-family: 'Playfair Display', serif;
            width: 82%;
        }

        a {
            @include linkLight;
        }
    }
}

#explore {
    background: #f6f6f6;
    margin: 200px 0px;

    @media screen and (max-width: 1025px) {
        margin: 80px 0;
    }

    @media screen and (max-width: 640px) {
        margin: 50px 0;
    }

    .explore-text {
        padding: 6vw;

        @media screen and (max-width: 1025px) {
            padding: 8vw 6vw;
        }

        h1,
        h2,
        h3 {
            @include title;
            color: #5c5c5c;
            font-size: 2em;
        }

        p {
            @include copy;
        }

        a {
            @include btn;
        }
    }

    .explore-slider {
        height: 100%;

        ul {
            list-style-type: none;
            margin: 0px;
        }

        .owl-prev,
        .owl-next {
            position: absolute;
            height: 70px;
            width: 70px;
            line-height: 70px;
            text-align: center;

            i {
                color: #fff;
                padding: 10px;
                font-size: 24px;
            }
        }

        .owl-prev {
            background-color: lighten($brand, 10%);
            left: -7px;
            bottom: 65px;
        }

        .owl-next {
            background-color: $brand;
            left: -7px;
            bottom: -7px;
        }
    }
}

#cottages {
    .grid-x {
        padding: 0% 3vw;

        @media screen and (max-width: 1030px) {
            padding: 0 2%;
        }


    }

    .cottages-text {
        @media screen and (max-width: 1025px) {
            margin-bottom: 4em;
        }

        h1,
        h2,
        h3 {
            @include title;
            font-size: 2em;
        }

        p {
            @include copy;
        }

        a {
            @include btn;

            &:hover {
                &::after {
                    color: #fff;
                    opacity: 0.7;
                }
            }

            &::after {
                font-family: 'FontAwesome';
                content: '\f105';
                display: inline-block;
                margin-left: 10px;
                color: $brand;
                transition: 0.3s;
            }
        }
    }

    .cottages-imgs {
        > div {
            img {
                border: solid 5px #fff;
            }
        }
    }

    .cottages-img-1 {
        margin-right: 30px;

        @media screen and (max-width: 800px) {
            text-align: left;
        }
    }

    .cottages-img-2 {
        margin-top: -100px;

        @media screen and (max-width: 1025px) {
            margin-top: -220px;
        }

        @media screen and (max-width: 800px) {
            display: none;
        }


    }

    .cottages-img-3 {
        margin-top: -150px;
        position: relative;

        @media screen and (max-width: 1025px) {
            margin-top: -20px;
        }

        @media screen and (max-width: 800px) {
            margin-top: 45px;
        }

        > img {
            @media screen and (max-width: 1025px) {
                display: none;
            }
        }

        .cert-logos {
            img {
                @media screen and (max-width: 1025px) {
                    border: none;
                }
            }
        }

        p#circ-text2 {
            position: absolute;
            bottom: 120px;
            right: 350px;
            display: block;
            color: #af965d;
            font-size: 18px;
            font-family: $f-serif;
            font-weight: bold;
            font-family: 'Playfair Display', serif !important;

            @media screen and (max-width: 1025px) {
                display: none;
            }
        }
    }
}

#view-cottages {
    margin-top: -300px;

    @media screen and (max-width: 1025px) {
        margin-top: 0px;
    }

    @media screen and (max-width: 640px) {
        margin-top: 2.5em;
    }

    h4 {
        @include title;
        font-size: 1.6em;
    }

    .grid-x {
        padding: 0% 3vw;
    }

    .view-cottage-1,
    .view-cottage-2 {
        width: 49%;
        float: left;

        @media screen and (max-width: 800px) {
            width: 100%;
        }

        img {
            border: solid 5px #fff;
        }

        p {
            font-family: 'Playfair Display', serif;
            letter-spacing: 1px;
            font-weight: bold;
        }
    }

    .view-cottage-1 {}

    .view-cottage-2 {
        margin-left: -30px;
        margin-top: 35px;

        a:last-of-type {
            text-align: right;
            float: right;
            padding: 11px 24px;
            background: 0 0;
            background: #af965d;
            font-size: 13px;
            letter-spacing: 1px;
            color: #fff;
            margin-top: .8em;
            font-family: 'Playfair Display', sans-serif;
            text-transform: uppercase;

            &::after {
                font-family: 'FontAwesome';
                content: '\f105';
                display: inline-block;
                margin-left: 10px;
                opacity: 0.7;
            }
        }

        @media screen and (max-width: 800px) {
            margin: 0px;
        }
    }
}

#home-gallery {
    .grid-x {
        margin: 110px auto;

        @media screen and (max-width: 640px) {
            margin-top: 40px;
        }
    }

    h1,
    h2,
    h3 {
        @include title;
        font-size: 2em;
    }

    p {
        @include copy;
        margin: 0;
    }

    a {
        @include btn;
    }

    .h-gallery-imgs {
        position: relative;

        @media screen and (max-width: 1025px) {
            margin-top: 4.6em;
        }
    }

    .h-gallery-text {
        a {
            &:hover {
                &::after {
                    color: #fff;
                    opacity: 0.7;
                }
            }

            &::after {
                font-family: 'FontAwesome';
                content: '\f105';
                display: inline-block;
                margin-left: 10px;
                color: $brand;
                transition: 0.3s;
            }
        }
    }

    .h-gallery-img-1 {}

    .h-gallery-img-2 {
        position: absolute;
        right: -50px;
        top: -65px;
        border: solid 6px #fff;

        @media screen and (max-width: 1025px) {
            display: none;
        }
    }

    .h-gallery-img-3 {
        position: absolute;
        right: -15px;
        bottom: -60px;
        border: solid 6px #fff;

        @media screen and (max-width: 640px) {
            display: none;
        }
    }
}

.reviews-logos {
    margin-bottom: 5em;

    @media screen and (max-width: 640px) {
        margin-bottom: 3em;
    }

    a {
        display: inline-block;
        max-width: 100%;

        @media screen and (max-width: 640px) {
            display: block;

            &:first-of-type {
                margin-bottom: 1.5em;
            }
        }
    }

    img {
        margin: 0px 25px;
        max-width: 90%;
    }
}

#reviews {
    margin: 200px 0 150px;

    @media screen and (max-width: 1025px) {
        margin: 150px 0 100px;
    }

    @media screen and (max-width: 640px) {
        margin: 80px 0 100px;
    }

    .large-6 {
        padding: 0px 60px;

        @media screen and (max-width: 1025px) {
            padding: 0px 20px;
            padding-bottom: 80px;

            &:last-of-type {
                padding-bottom: 0px;
            }
        }
    }

    img {}

    ul {
        list-style-type: none;
        margin: 0px;
        margin-top: 50px;

        p {
            font-size: 15px;
        }

        .owl-controls {
            .owl-dot {
                display: inline-block;
                width: 20px;
                height: 3px;
                margin-right: 6px;
                background: #e4e4e4;

                &.active {
                    background: $brand2;
                }
            }
        }
    }

    a.btn {
        @include btn;
        display: inline-block;
        margin-top: 1.5em;
        border: none;
        background: $brand;
        color: #fff;
        font-size: 14px;

        &:hover {
            &::after {
                color: #fff;
                opacity: 1;
            }
        }

        &::after {
            font-family: 'FontAwesome';
            content: '\f105';
            display: inline-block;
            margin-left: 10px;
            color: #fff;
            opacity: 0.7;
            transition: 0.3s;
        }
    }
}

#map-wrap {
    position: relative;

    .map-overlay {
        position: absolute;
        left: 55px;
        top: 50%;
        transform: translateY(-50%);
        width: 30%;
        background: white;
        padding: 3.5vw;
        z-index: 3;

        @media screen and (max-width: 1300px) {
            width: 38%;
        }

        @media screen and (max-width: 1025px) {
            position: static;
            transform: none;
            width: 100%;
            padding: 7vw;
        }

        h1,
        h2,
        h3,
        h4 {
            @include title;
            font-size: 26px;
            text-transform: uppercase;
            font-family: 'Playfair Display', serif;
        }
    }

    #map {
        height: 650px;
    }
}

footer {
    background: $brand2;
    padding: 60px 0px;

    .social {
        a {
            color: #fff;
            margin: 0px 10px;
            font-size: 24px;
        }
    }

    .footer-menu {
        margin: 40px 0px 60px;

        ul {
            list-style-type: none;
            margin: 0;
            text-align: center;

            li {
                display: inline-block;
                margin: 0px 15px;

                @media screen and (max-width: 640px) {
                    display: block;
                }

                a {
                    color: #fff;
                    opacity: 0.5;
                    font-weight: lighter;
                    transition: 0.3s;
                    text-transform: uppercase;
                    letter-spacing: 0px;
                    font-size: 13px;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .footer-bot-left,
    .footer-bot-mid,
    .footer-bot-right {
        p {
            color: #fff;
            font-weight: lighter;

            a {
                color: $brand;
            }
        }
    }

    .footer-bot-mid {
        p {
            font-family: 'Playfair Display', serif;
            font-weight: normal;
            font-size: 17px;
        }
    }
}


#page-content .grid-x {
    padding: 6em 0em;

    @media screen and (max-width: 1025px) {
        padding: 4em 0;
    }

    @media screen and (max-width: 640px) {
        padding: 2.5em 0;
    }

    .cell {
        padding: 0em 4vw;
        
        &.popup-gallery {
            padding: 0;
        }

        h1,
        h2,
        h3,
        h4 {
            font-weight: bold;
            font-size: 2.2em;
            color: #af965d;
        }

        h3 {
            font-size: 20px;
            margin: 25px 0 10px;
        }
    }
}

.popup-gallery {
    a {
        display: block;
        position: relative;
    }
    .desc {
        position: absolute;
        left: 25px;
        bottom: 25px;
        color: #fff;
        font-size: 18px;
        line-height: 1;
    }
}




/* ===========================] Media Queries [======================== */


@media screen and (max-width: 1025px) {}


@media screen and (max-width: 640px) {}