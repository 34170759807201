/* News Module Custom Styles */

.news-card-cont{
  display: flex;
}

.news-articles {
	display: flex;
	width: 100%;
	flex-flow: row wrap;
}

.news-card-cont.column.small-12.medium-6 {
	margin-bottom: 1.8rem;
}

.news-card{
  box-sizing: border-box;
  border: 1px solid #CBCBCB;
  padding: 2rem;
}
   
